.profile-title {
  text-align: center;
}

.profile-subtext {
  text-align: center;
  padding: 20px 0px 40px 0px;
}

.logo-wrapper {
  padding: 10px 0px;
}

.logo {
  padding: 0px 20px 0px 0px;
}